import React from 'react'

function Footer() {
    return (
        <>
            <footer id="footer"> 

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3>Maputo</h3>
                            <p>
                            BEIRO 1254 <br/>
                            MAPUTO,<br/>
                            MOZAMBIQUE <br/><br/>
                            <strong>Phone:</strong> +258 84 5523809<br/>
                            <strong>Email:</strong> info@maputoherbalfarm.com<br/>
                            </p>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#portfolio">PORTFOLIO</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#certification">CERTIFICATION</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#contact">Contact Us</a></li>
                            </ul>
                        </div>

                        {/* <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                            </ul>
                        </div> */}

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>follow us on social media</p>
                            <div className="social-links mt-3">
                            <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                            <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                            <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                            <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                            <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>

                        </div>
                </div>
                </div>

                <div className="container py-4">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Maputo</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                    
                        Designed by <a href="#">Devopia</a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
