import React from 'react'

function Tinctures() {
    return (
        <>
        <section id="portfolio-details" class="portfolio-details aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                <div class="container">
                <h2>OUR HERBAL TINCTURES</h2>
                        <div class="portfolio-description row">
                        
    
                            <ul className="col-md-4">
                          
                                <li><strong> Expel Parasite Formula</strong></li>
                                <li><strong>C-B-S Detox</strong></li>
                                <li><strong>Inflamm Relief</strong></li>
                                <li><strong>Prostate Support</strong></li>
                                <li><strong>Stress Soother</strong></li>
                            </ul>
                            <ul className="col-md-4">
                          
                                <li><strong>  Pain Relief</strong></li>
                                <li><strong>Triple A Immune Boost</strong></li>
                                <li><strong>Chemo support</strong></li>
                                <li><strong>Rescue for Colds and 'Flu</strong></li>
                                <li><strong>Rescue Anti-Histamine</strong></li>
                            </ul>
                            <ul className="col-md-4">
                          
                                <li><strong>  Meno Balance</strong></li>
                                <li><strong> Meno Soothe</strong></li>
                                <li><strong> Piles Rlief</strong></li>
                                <li><strong>Arthritis drops</strong></li>
                                <li><strong>Restless Leg and Cramps drops</strong></li>
                            </ul>
                                   
  
                        </div>
                        

                </div>
        </section>
        </>
    )
}

export default Tinctures
