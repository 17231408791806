import React from 'react'

function Features() {
    return (
        <>

<section id="features" className="features">
        <div className="container">

          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="card" data-aos="fade-up">
                <img src="assets/img/features-1.jpg" className="card-img-top" alt="..."/>
                <div className="card-body">
                  <i className="bx bx-tachometer"></i>
                  <h5 className="card-title"><a href="">Our Mission</a></h5>
                  <p className="card-text">To bring all our Medical Solutions to International standards and make it more valuable and universally accessible. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 mt-md-0 d-flex align-items-stretch">
              <div className="card" data-aos="fade-up" data-aos-delay="150">
                <img src="assets/img/features-2.jpg" className="card-img-top" alt="..."/>
                <div className="card-body">
                  <i className="bx bx-file"></i>
                  <h5 className="card-title"><a href="">Our Plan</a></h5>
                  <p className="card-text">We have grown to become one of the best in herbal and plant extracts (liquid and powder extracts) in SouthEast Africa with very high capacity</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 mt-lg-0 d-flex align-items-stretch">
              <div className="card" data-aos="fade-up" data-aos-delay="300">
                <img src="assets/img/features-3.jpg" className="card-img-top" alt="..."/>
                <div className="card-body">
                  <i className="bx bx-show"></i>
                  <h5 className="card-title"><a href="">Our Vision</a></h5>
                  <p className="card-text">Our company is committed to providing the best healthcare solutions. We intend Reaching the whole world </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

        </>

    )
}

export default Features
