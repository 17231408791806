import React from 'react'

function Services() {
    return (
        <>

<section id="services" className="services">
        <div className="container">

          <div className="section-title" data-aos="fade-down">
            <span>Services</span>
            <h2>Services</h2>
            <p>We offers a large variety of liquid and powder extracts. Our Plant extraction is a process that aims to extract certain components present in plants. 
                It is a solid/liquid separation operation: A solid object (the plant) is placed in contact with a fluid (the solvent). 
                The plant components of interest are then solubilised and contained within the solvent. The solution thus obtained is the desired extract. </p>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <i className="icofont-computer" style={{color: "#0ea5e0;"}}></i>
                <h4><a href="#">HERBAL OIL EXTRACTS</a></h4>
                <p>Eucalyptus oil, raventsara oil (cinnamomum camphora), Healing Herbal Oils, Helichrysum Herbal Oil, Calendula Oil, Comfrey Oil, Earache Oil, Shingles Oil, Sinus Oil,
                     Libido Massage Oil, Infertility Massage oil, Luxigroxin Herbal Oil, POCOS Massage Oil.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <i className="icofont-chart-bar-graph" style={{color: "#07cc70;"}}></i>
                <h4><a href="#">DRY HERBS POWER EXTRACTS</a></h4>
                <p>Centella asiatica dried leaves ( gotu kola, pennyworth), moringa powder, turmeric finger, cinnamon broken (cassia), tapioca chips.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                <i className="icofont-image" style={{color: "#e40373;"}}></i>
                <h4><a href="#">AGRIC</a></h4>
                <p>Cloves, black pepper, vanilla, white beans, black eye beans, red beans, lubya beans, coffee beans, cocoa beans, green mung.</p>
              </div>
            </div>
           
           
          </div>

        </div>
      </section>
      <section id="faq" className="faq">
        <div className="container-fluid">

          <div className="section-title" data-aos="fade-down">
            <span>OUR SINGLE HERBS</span>
            <h2>OUR SINGLE HERBS</h2>
            <p></p>
          </div>

          <div className="faq-list row ">
            <ul className=" col-md-4 col-lg-4">
              <li data-aos="fade-up" style={{ width: "30em"}}>
              African Potato – Hypoxis heamerocallidea<br/>
                Shepherd's Purse - Capsella bursa<br/>
                Stinging Nettle – Urtica dioica<br/>
                Sutherlandia – Sutherlandia frutescens<br/>
                Thyme – Thymus vulgaris<br/>
                Valerian – Valeriana officinalis<br/>
                Violet Leaf – Viola odorata<br/>
                Wormwood - Artemisia absinthium<br/>
                Chinese Wormwood/Sweet Annie - Artemisia annua<br/>
                Wilde dagga - Leonotus leanurus<br/>
                Yarrow – Achillea millefolium<br/>
                Devil's Claw - Harpagophytum procumbens<br/>
                Black Cumin - Nigella sativa<br/>
                White Sage - Salvia apiana<br/>
                Hawthorn Berries - Crataegus spesies<br/>
                Passiflora - Passiflora incarnata<br/>
                Raspberry - Rubus idaeus<br/>
                Chamomile - Matricaria recutita<br/>
                Chickweed - Stellaria media<br/>
                Tea Tree Leaf- Melaleuca alternifolia<br/>
                Fuller's Teasel - Dipsacus sativus<br/>
                Buchu – Agathosma crenulata<br/>
                Bladderwrack - Fucus vesiculosus<br/>
                Black Cohosh - Actaea racemosa<br/>
                Cat’s Claw – Uncaria tomentosa<br/>
                Chaste Berries - Vitex agnus-castus
              </li>

              <li data-aos="fade-up" data-aos-delay="100" style={{ width: "30em"}}>
                Alfalfa – Medicago sativa <br/>
                Wormwood, Wilde Als – Artemisia Afra <br/>
                Ashwaganda – Withania somnifera<br/>
                Helichrysum - Asteraceae<br/>
                Basil - Occimum basilicum<br/>
                Burdock – Arctium lappa<br/>
                Calendula – Calendula officinalis
              </li>

              <li data-aos="fade-up" data-aos-delay="200" style={{ width: "30em"}}>
                Catnip – Nepeta cataria<br/>
                Centella – Centella asiatica<br/>
                Comfrey – Symphytum officinale<br/>
                Corn Flower: Blue – Centaurea cyanus<br/>
                Dandelion – Taraxacum officinale<br/>
                Dandelion Root - Taraxacum officinale<br/>
                Echinacea - Echinacea purpurea<br/>
                Elder Flower - Sambucus nigra<br/>
                Elder Leaf - Sambucus nigra<br/>
                Fennel – Foeniculum vulgaris<br/>
                Feverfew – Tanacetum parthenium<br/>
                Golden Rod – Solidago aureus<br/>
                Horsetail – Equisetum arvense<br/>
                Lavender – Lavendula sp. Various<br/>
                Lemon Balm – Melissa officinalis<br/>
                Lemon Grass – Cymbopogon citratus<br/>
                Lemon Verbena – Lippia citriodora<br/>
                Marjoram - Oreganum marjorana<br/>
                Marshmallow Root - Althea officinalis<br/>
                Milk Thistle - Silybum marianum<br/>
                Mint (mixed mints) – Mentha sp<br/>
                Mint, Peppermint – Mentha piperita<br/>
                Oreganum - Oreganum vulgare<br/>
                Olive Leaf - Olea europaea<br/>
                Parsley – Petroselinum crispum<br/>
                Plantain – Plantago major<br/>
                Red Clover - Trifolium pratense<br/>
                Rosemary – Rosmarinus officinalis<br/>
                Rue - Ruta graveolens<br/>
                Sage – Salvia officinalis
                Lavender Buds - Lavendula sp. Various
              </li>

             
            

            </ul>

            <ul className=" col-md-4 col-lg-4">
              <li data-aos="fade-up" style={{ width: "30em"}}>
                Hoodia - Hoodia gordonii<br/>
                Hyssop - Hyssopus officinalis<br/>
                Lady's Mantle - Alchemilla xanthochlora<br/>
                Liquorice - Glycyrrhiza glabra<br/>
                Lungwort - Pulmonaria officinalis<br/>
                Maca - Lepidium meyenii<br/>
                Mugwort - Artemisia vulgaris<br/>
                Motherwort - Leonurus cardiaca<br/>
                Neem - Azadirachta indica<br/>
                Strawberry - Fragaria vesca<br/>
                Witch Hazel - Hamamelis virginiana<br/>
                Rooi Wortel - Bulbine natalensis<br/>
                Elderberry Berries<br/>
                Siberian Ginseng - Eleutherococcus senticosus<br/>
                Mullein - Verbascum thapsus<br/>
                Senna - Cassia angustifolia<br/>
                Stinging Nettle root - Urtica dioica<br/>
                Roselle - Hibiscus sabdariffa<br/>
                Horehound - Marraubium vulgare<br/>
                Small Flower Willow Herb - Epilobium parviflorum<br/>
                Klip Dagga - Leonotis nepetifolia<br/>
                Agrimony - Agrimonia eupatoria<br/>
                Couch Grass - Agropyron repens<br/>
                Ginkgo Biloba<br/>
                Green Tea - Camellia sinensis<br/>
                Sarsaparilla - Smilax species<br/>
                </li>

              <li data-aos="fade-up" data-aos-delay="100" style={{ width: "30em"}}>
              White Willow bark - Salix alba<br/>
                Astragalus root - Astragalus membranaceus<br/>
                Moringa - Moringa oleifera<br/>
                Aloe Vera - Aloe barbadensis<br/>
                Echinacea root<br/>
                Eyebright- Euphrasia stricta<br/>
                Ginger - Zingiber officinale<br/>
              </li>

              <li data-aos="fade-up" data-aos-delay="200" style={{ width: "30em"}}>
              Clubmoss - Lycopodium clavatum<br/>
                Greater Celandine - Chelidonium majus<br/>
                Mistletoe - Viscum album<br/>
                Orris root - Iris germanica<br/>
                Skullcap - Scutellaria lateriflora<br/>
                Sheep Sorrel - Rumex acetosa<br/>
                St. John's Wort - Hypericum perforatum<br/>
                Vervain - Verbena officinalis<br/>
                Angelica - Angelica archangelica<br/>
                Arrow Root - Maranta arundinacea<br/>
                African Ginger / Wild Ginger - Siphonochilus aethiopicus.<br/>
                Frankinsense – Boswelia serrate<br/>
                Butchers Broom – Ruscus aculeatis<br/>
                Calamus Root / Sweet Flag – Acorus calamus<br/>
                Cornsilk - Zea mays (maidis stigma)<br/>
                Don Quai – Angelica sinensis<br/>
                Bedstraw / Cleavers - Galium aparine<br/>
                Buckwheat – Fagopyrum esculentum<br/>
                Boldo leaf – Peumus boldus<br/>
                Dill – Anethum graveolens<br/>
                Fumitory Herb – Fumaria officinalis<br/>
                Red Grape leaf – Vitis vinifera<br/>
                Henna / Alkaner – Cassia obovata<br/>
                Henna, Black – Indogofera tinctoria<br/>
                Henna, Red – Lawsonia inermis<br/>
                Holy/Blessed Thistle – Cnicus benedictus<br/>
                Hops – Humulus lupulus<br/>
                Jasmine – Jasminum officinale<br/>
                Knotgrass – Polygonum aviculare<br/>
                Madder Root - Rubia tinctorum<br/>
                Lobelia / Asthma weed<br/>
                
              </li>


             
            </ul>

            <ul className=" col-md-4 col-lg-4">
              <li data-aos="fade-up" style={{ width: "30em"}}>
                 Fenugreek seed – Trigonella foenum-graecum<br/>
                Hibiscus flower – Hibiscus rosa-sinensis<br/>
                Mate tea / Yerba Mate – Ilex paraguariensis<br/>
                Meadowsweet – Filipendula ulmaria<br/>
                Mullein flowers - Verbascum thapsus<br/>
                Oak Bark – Quercus alba<br/>
                Betony – Stachys betonica<br/>
                Billberry – Vaccinium myrtillus<br/>
                Billberry – Vaccinium myrtillus<br/>
                Blackberry – Rubus fruticosa<br/>
                Butterbur Root – Petasites hybridus<br/>
                Butterfly Pea Flowers – Clitoria ternatea<br/>
                Centuary / Feverwort – Centaurium erythraea<br/>
                Cinnamon – Cinnamomum aromaticum<br/>
                Cinnamon – Cinnamomum aromaticum<br/>
                Coltsfoot – Tussilago farfara<br/>
                Crampbark – Viburnum opulus<br/>
                Chicory Leaf – Chichorium intybus<br/>
                Elecampane – Inula helenium<br/>
                Fennel Seed – Foeniculum vulgare<br/>
                Garlic – Allium sativa<br/>
                Wild Garlic – Allium ursinum<br/>
                Gentian Root – Gentiana lutea<br/>
                Goji berry – Lycium barbarum<br/>
                Hydrangea Root – Hydrangea arborescence<br/>
                Juniper berries – Juniperus communis<br/>
                
              </li>

              <li data-aos="fade-up" data-aos-delay="100" style={{ width: "30em"}}>
                Lemon (Peel) - Citrus limon<br/>
                Lily of the Valley – Convallaria majalis<br/>
                Lime Blossom - Tiliia cordata<br/>
                Marshmallow leaf – Althaea officinalis<br/>
                Spearmint – Mentha spicata<br/>
                Myrrh – Commpihora myrrha<br/>
                Oats Straw – Avena sativa<br/>
                
              </li>

              <li data-aos="fade-up" data-aos-delay="200" style={{ width: "30em"}}>
                Pau D Arco / Lapacho – Tabebuiae impetiginosa<br/>
                Patchouli – Pogostemon patchouli<br/>
                Pelargonium sidoides – Pelargonium sidoides<br/>
                Psyllium Seed – Plantago ovate<br/>
                Chinese Rhubarb – Rheum palmatum<br/>
                Rose Petals<br/>
                Rosehip – Rosa canina<br/>
                Irish Sea Moss – Chondrus crispus<br/>
                Tansy – Tanecetum vulgare<br/>
                Turmeric – Curcuma longa<br/>
                Uva Ursi / Bearberry – Arctostaphylos uva-ursi<br/>
                Walnut – Juglans regia<br/>
                Yellow Dock root – Rumex Crispus<br/>
                Bedstraw / Cleavers - Galium aparine<br/>
                Buckwheat – Fagopyrum esculentum<br/>
                Boldo leaf – Peumus boldus<br/>
                Dill – Anethum graveolens<br/>
                Fumitory Herb – Fumaria officinalis<br/>
                Red Grape leaf – Vitis vinifera<br/>
                Henna / Alkaner – Cassia obovata<br/>
                Henna, Black – Indogofera tinctoria<br/>
                Henna, Red – Lawsonia inermis<br/>
                Holy/Blessed Thistle – Cnicus benedictus<br/>
                Hops – Humulus lupulus<br/>
                Jasmine – Jasminum officinale<br/>
                Knotgrass – Polygonum aviculare<br/>
                Madder Root - Rubia tinctorum<br/>
                Lobelia / Asthma weed<br/>
                Bedstraw / Cleavers - Galium aparine<br/>
                Buckwheat – Fagopyrum esculentum<br/>
                Wild Yam/Colic Root/Rheumatism Root – Dioscorea Villosa
              </li>

              

            </ul>
            
          </div>

          

        </div>
      </section>
        </>
    )
}

export default Services
