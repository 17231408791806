import Hero from './components/Hero';
import Header from './components/Header';
import About from './components/About.js';
// import Clients from './components/Clients'
import Features from './components/Features'
import Cta from './components/Cta';
import Services from './components/Services';
import Portofolio from './components/Portfolio'
import Tinctures from './components/Tinctures';
import Contact from './components/Contact';
import Footer from './components/Footer';

import { BrowserRouter as Router, Switch , Route} from 'react-router-dom'
import Certification from './components/Certification';


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
            <Route>
              
              <Hero/>
              <Header/>
              <About/>
              {/* <Clients/> */}
              <Features/>
              <Cta></Cta>
              <Services/>
              <Certification/>
              <Portofolio/>
              <Tinctures/>
              <Contact/>
              <Footer/>

            </Route>
        </Switch>
      </Router>
     
    </div>
  );
}

export default App;
