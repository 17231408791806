import React from 'react'
import emailjs from "emailjs-com";

function sendEmail(e) {
    // console.log('i was triggered')
    e.preventDefault();

    emailjs.sendForm('service_jhju33k', 'template_q8l1l6p', e.target, 'user_N3XUKSjSpASLMLC30CjyI')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
}




function Contact() {
    return (
        <>
            <section id="contact" className="contact">
        <div className="container">

            <div className="section-title" data-aos="fade-down">
                <span>MAPUTO HERBAL HEALTH PRODUCT</span>
                <h2>Contact Us</h2>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-12" data-aos="fade-up" data-aos-delay="100">
                <div className="info-box">
                    <i className="bx bx-map"></i>
                    <h3>Our Address</h3>
                    <p>BEIRO 1254, MAPUTO, MOZAMBIQUE</p>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                <div className="info-box">
                    <i className="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    
                    <p>info@maputoherbalfarm.com,<br/>m.masimba@maputoherbalfarm.com</p>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                <div className="info-box">
                    <i className="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p>CONTACT PERSON: MASIMBA MOYO</p>
                    <p>TEL: +258 84 5523809</p>
                </div>
                </div>
            </div>

          <form onSubmit={sendEmail}  data-aos="fade-up" data-aos-delay="400" >
            <div className="form-row">
              <div className="col-md-6 form-group">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div className="validate"></div>
              </div>
              <div className="col-md-6 form-group">
                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div className="validate"></div>
              </div>
            </div>
            <div className="form-group">
              <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
              <div className="validate"></div>
            </div>
            <div className="form-group">
              <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
              <div className="validate"></div>
            </div>
            {/* <div className="mb-3">
              <div className="loading">Loading</div>
              <div className="error-message"></div>
              <div className="sent-message">Your message has been sent. Thank you!</div>
            </div> */}
            <div className="text-center"><button type="submit">Send Message</button></div>
          </form>

        </div>
      </section>
        </>
    )
}

export default Contact
