import React, {useState} from 'react'

function Hero() {
    return (
        <>
         {/* this is the top hero */}
         <section id="hero">
            <div className="hero-container" data-aos="fade-up">
                <h1> <span>MAPUTO HERBAL FARM</span></h1>
                <h2>situated in Mozambique, Southeast cost of Africa, established since 1995</h2>
                <a href="#about" className="btn-get-started scrollto">Get Started</a>
            </div>
        </section>

        {/* hero end */}
        </>
    )
}

export default Hero
