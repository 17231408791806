import React from 'react'

function About() {
    return (
       <>

      <section id="about" className="about">
        <div className="container">

            <div className="row">
        <div style={{ backgroundImage: "url(assets/img/about.jpg)" }} data-aos="fade-right" className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
                <div className="col-xl-7 pt-4 pt-lg-0 d-flex align-items-stretch">
                    <div className="content d-flex flex-column justify-content-center" data-aos="fade-left">
                        <h3>Whats Maputo Herbal Farm?</h3>
                        <p style={{ textAlign: "justify"}}>
                        Maputo Herbal Farm, situated in Mozambique, Southeast cost of Africa, established since 1995.
                         Owner Masimba Moyo has been in the industry for over 30 years.    
                        </p>
                        <div className="row">
                            <div className="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
                                <i className="bx bx-receipt"></i>
                                <h4>Maputo Herbal Farm</h4>
                                <p> was created with a unique layout. 
                                Meandering pathways to lead visitors from one point of interest to the next!</p>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
                                <i className="bx bx-cube-alt"></i>
                                <h4>Growth</h4>
                                <p>We have grown to become one of the best in herbal and plant extracts 
                         (liquid and powder extracts) in SouthEast Africa with very high capacity.</p>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
                                <i className="bx bx-images"></i>
                                <h4>Quality</h4>
                                <p> We produce the highest quality herbal medicinal products,
                          Our organic products are made from the finest organic herbs to support your health & wellness ,Quality is one of our core concerns.</p>
                            </div>
                            <div className="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="400">
                                <i className="bx bx-shield"></i>
                                <h4>Solutions</h4>
                                <p>Our company is committed to providing the best healthcare solutions.</p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End .content--> */}
            </div>
            </div>

        </div>
      </section>
      {/* <!-- End About Section --> */}

       </>

    )
}

export default About
