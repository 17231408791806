import React from 'react'

function Cta() {
    return (
       <>
        <section id="cta" class="cta">
        <div class="container">

          <div class="text-center" data-aos="zoom-in">
            <h3>Want our Product?</h3>
            <p>Introducing Lady's Mantle , the Maputo Herbal way. Our new line includes
                 four formulas that combine Aloe Vera with some of your favorite herbs — and the same high quality you expect from us..</p>
            <a class="cta-btn" href="#">Contact Us Today</a>
          </div>

        </div>
      </section>
       </>
    )
}

export default Cta
