import React from 'react'
import { NavLink } from "react-router-dom";

function Header() {
    return (
        <>
        
        <header id="header" class="d-flex align-items-center">
      <div class="container d-flex align-items-center">

        <div class="logo mr-auto">
          <h1 class="text-light"><a href="index.html"><span>MAPUTO</span></a></h1>
          {/* <!-- Uncomment below if you prefer to use an image logo --> */}
          {/* <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>--> */}
        </div>

        <nav class="nav-menu d-none d-lg-block">
          <ul>
            <li class="active"><a href="index.html">HOME</a></li>
            <li><a href="#about">ABOUT US</a></li>
            <li><a href="#services">OUR SERVICES</a></li>
            <li><a href="#portfolio">PORTFOLIO</a></li>
            <li><a href="#certification">CERTIFICATION</a></li>
            <li><a href="#contact">CONTACT US</a></li>

          </ul>
        </nav>
        {/* <!-- .nav-menu --> */}

      </div>
    </header>
    {/* <!-- End Header --> */}
       
        </>
    )
}

export default Header
