import React from 'react'

function Certification() {
    return (
        <>

<section id="certification" class="features">
        <div class="container">
        <div className="section-title" data-aos="fade-down">
            <span>CERTIFICATION</span>
            <h2>CERTIFICATION</h2>
            <p> We are not a beginning organization, we are Certified! </p>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="card aos-init aos-animate" data-aos="fade-up">
                <img src="assets/img/cert1.jpg" class="card-img-top" alt="..."/>
                <div class="card-body">
                 
                  
                  
                </div>
              </div>
            </div>
            
            <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
              <div class="card aos-init aos-animate" data-aos="fade-up">
                <img src="assets/img/cert2.jpg" class="card-img-top" alt="..."/>
                <div class="card-body">
                  
                  
                  
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>


        </>
    )
}

export default Certification
